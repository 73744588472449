import { IndHttpAdapter } from '~/core/adapters/indHttpAdapter';
import logger from '~/core/providers/logger';

const dataTagEndpoints = {
  getDataTags: `rag/data-tags`,
};

const indApiAdapter = new IndHttpAdapter();

const getDataTags: () => Promise<DataTags[]> = async () => {
  const response = await indApiAdapter.get<DataTags[]>({
    endpoint: dataTagEndpoints.getDataTags,
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data as DataTags[];
};

export { getDataTags };
