import { useFeatureFlagEnabled } from 'posthog-js/react';

export type FEATURE_FLAGS =
  | 'allow-sadl' // named so as this is being used by the BE as well
  | 'fe-allow-batch-data-room-functions'
  | 'fe-allow-new-style-references'
  | 'fe-allow-new-style-qc'
  | 'fe-allow-doc-editor-comments-in-draft'
  | 'fe-allow-template-management'
  | 'fe-allow-zero-files-on-template-playgrounds'
  | 'fe-allow-document-editor-concept'
  | 'fe-better-qc'
  | 'fe-show-templated-table-instructions'
  | 'fe-templates-allow-module-3'
  | 'fe-gap-analysis'
  | 'fe-extended-collab'
  | 'fe-m3-tables'
  | 'fe-exp-dashboard'
  | 'fe-allow-old-doc-editor-table-references'
  | 'fe-version-history-restore'
  | 'fe-allow-m1';

/**
 * Checks if a feature is enabled or not. If the result is undefined, probably the feature is
 * either missing from the posthog env, or loading.
 * @param feature the name of the feature flag
 * @returns a bool specifying if it's enabled or not, otherwise undefined
 */
export const useIsFeatureEnabled = (
  feature: FEATURE_FLAGS,
): boolean | undefined => {
  return useFeatureFlagEnabled(feature);
};
