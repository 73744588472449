import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { useEffect } from 'react';

import { useIsFeatureEnabled } from '~/core/hooks/core/useIsFeatureEnabled';
import { useToast } from '~/core/hooks/core/useToast';
import logger from '~/core/providers/logger';
import * as DataTagService from '~/core/services/DataTagService/DataTagService';
import {
  cloneTemplate,
  deleteTemplateByName,
  getGenerationTemplatesbyInd,
  getGenerationTemplatesByName,
  getOrgContentTemplates,
  renameTemplate as renameTemplateServiceCall,
  savePromptConfig,
} from '~/features/templates-space/services/TemplatesService/TemplatesService';
import { useUserPermissionsMap } from '~/global-hooks/useUserPermissionsMap';

import {
  CreateTemplatePayload,
  HarmonizedGenerationTemplate,
  OrgTemplates,
} from '../types';

export const QueryKeys = {
  DELETE_TEMPLATE: ({ templateName }: { templateName: string }) => [
    'delete-template',
    templateName,
  ],
  GET_GENERATION_TEMPLATES: ({ indId }: { indId: string }) => [
    'generation-templates',
    indId,
  ],
  GET_GENERATION_TEMPLATE_BY_NAME: ({ name }: { name?: string }) => [
    'generation-template-by-name',
    name,
  ],
  GET_ORG_CONTENT_TEMPLATES: ['org-content-templates'],
  GET_DATA_TAGS: ['data-tags'],
};

export type UseTemplatesProps = {
  indId?: string;
  templateName?: string;
};

export type UseTemplatesReturn = {
  deleteTemplate: UseMutationResult<any, Error, string>;
  indTemplate: UseQueryResult<HarmonizedGenerationTemplate, Error>;
  dataTags: DataTags[] | undefined;
  saveTemplate: UseMutationResult<any, Error, CreateTemplatePayload>;
  orgContentTemplates: UseQueryResult<OrgTemplates, Error>;
  orgTemplateByName?: UseQueryResult<HarmonizedGenerationTemplate, Error>;
  duplicateTemplate: UseMutationResult<
    any,
    Error,
    { sourceTemplateName: string; newTemplateName: string }
  >;
  renameTemplate: UseMutationResult<
    any,
    Error,
    { originalTemplateName: string; newTemplateName: string }
  >;
  refetchTemplate: () => void;
  allowModule3?: boolean;
  allowModule1?: boolean;
};

export const useTemplates = ({
  indId,
  templateName,
}: UseTemplatesProps): UseTemplatesReturn => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { hasPermission } = useUserPermissionsMap();

  const canAccessTemplates = hasPermission({
    securedEntity: 'template_administration',
    documentId: null,
    indId: indId ?? null,
  })?.can_read;

  const allowModule1 = useIsFeatureEnabled('fe-allow-m1') ?? false;
  const allowModule3 = useIsFeatureEnabled('fe-templates-allow-module-3');
  const allowSADL = useIsFeatureEnabled('allow-sadl');

  const indTemplate = useQuery<HarmonizedGenerationTemplate, Error>({
    queryKey: QueryKeys.GET_GENERATION_TEMPLATES({ indId: indId || '' }),
    queryFn: () => getGenerationTemplatesbyInd(indId || ''),
    enabled: !!canAccessTemplates && !!indId,
    staleTime: 0,
  });

  const orgTemplateByName = useQuery<HarmonizedGenerationTemplate, Error>({
    queryKey: QueryKeys.GET_GENERATION_TEMPLATE_BY_NAME({ name: templateName }),
    queryFn: () => getGenerationTemplatesByName(templateName!),
    enabled:
      !!templateName &&
      templateName.toLowerCase() !== 'weave baseline template',
    staleTime: 0,
  });

  const saveTemplate = useMutation<void, Error, CreateTemplatePayload>({
    mutationFn: (payload: CreateTemplatePayload) => savePromptConfig(payload),
    onError: (error) => {
      toast.error(
        'We ran into some issues and your template was not saved. Please try again.',
      );
      logger.logError(`Error saving prompt config: ${error}`, { error });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_GENERATION_TEMPLATES({
          indId: indId || '',
        }),
      });
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_GENERATION_TEMPLATE_BY_NAME({
          name: templateName || '',
        }),
      });
      toast.success('Your template has been saved!');
    },
  });

  const duplicateTemplate = useMutation<
    void,
    Error,
    { sourceTemplateName: string; newTemplateName: string }
  >({
    mutationFn: ({
      sourceTemplateName,
      newTemplateName,
    }: {
      sourceTemplateName: string;
      newTemplateName: string;
    }) => cloneTemplate({ sourceTemplateName, newTemplateName }),
    onError: (error) => {
      toast.error(
        `We weren't able to duplicate your template.  Please try again.`,
      );
      logger.logError(`Error duplicating template: ${error}`, { error });
    },
    onSuccess: (_data, { sourceTemplateName, newTemplateName }) => {
      toast.success(
        `Template '${sourceTemplateName}' has been copied to '${newTemplateName}'!`,
      );
    },
  });

  const renameTemplate = useMutation<
    void,
    Error,
    { originalTemplateName: string; newTemplateName: string }
  >({
    mutationFn: ({
      originalTemplateName,
      newTemplateName,
    }: {
      originalTemplateName: string;
      newTemplateName: string;
    }) => renameTemplateServiceCall({ originalTemplateName, newTemplateName }),
    onError: (error) => {
      toast.error(
        `We weren't able to rename your template.  Please try again.`,
      );
      logger.logError(`Error renaming template: ${error}`, { error });
    },
    onSuccess: (_data, { originalTemplateName, newTemplateName }) => {
      toast.success(
        `Template '${originalTemplateName}' has been renamed to '${newTemplateName}'!`,
      );
    },
  });

  const orgContentTemplates = useQuery<OrgTemplates, Error>({
    queryKey: QueryKeys.GET_ORG_CONTENT_TEMPLATES,
    queryFn: () => getOrgContentTemplates(),
    enabled: !!canAccessTemplates,
  });

  const deleteTemplate = useMutation<void, Error, string>({
    mutationFn: deleteTemplateByName,
    onError: (error) => {
      toast.error(
        'We ran into some issues and your template was not deleted. Please try again.',
      );
      logger.logError(`Error deleting template: ${error}`, { error });
    },
    onSuccess: () => {
      toast.success(`Your template has been deleted!`);
    },
  });

  const refetchTemplate = () => {
    indTemplate.refetch();
  };

  const { data: dataTags } = useQuery<DataTags[], Error>(
    {
      queryKey: QueryKeys.GET_DATA_TAGS,
      queryFn: async () => await DataTagService.getDataTags(),
    },
    queryClient,
  );

  // Clear queries when the component initially mounts
  useEffect(() => {
    if (allowSADL !== undefined && allowSADL === false) {
      queryClient.clear();
    }
  }, []);

  return {
    allowModule1,
    allowModule3,
    deleteTemplate,
    indTemplate,
    dataTags,
    saveTemplate,
    orgContentTemplates,
    orgTemplateByName: templateName ? orgTemplateByName : undefined,
    duplicateTemplate,
    renameTemplate,
    refetchTemplate,
  };
};
